import React from "react"
import logo from "../../static/logo-white.png"
import Layout from "../components/layout"
import styles from "./index.module.scss"

class Index extends React.Component {
  render() {
    return (
      <Layout title="Mordecai Ventures">
        <div className={styles.index}>
          <img className={styles.logo} src={logo} alt="Mordecai Ventures logo" />
        </div>
      </Layout>
    )
  }
}

export default Index
